/*

=========================================================
* Now UI Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/css/now-ui-kit.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css";
import "./assets/demo/nucleo-icons-page-styles.css";

// pages for this kit
import Index from "./views/Index.js";
import { createRoot } from 'react-dom/client';
import MantenimientoPage from "./views/pages/services/MantenimientoPage";
import ConstruccionPage from "./views/pages/services/ConstruccionPage";
import ForestalesPage from "./views/pages/services/ForestalesPage";
import CerramientosPage from "./views/pages/services/CerramientosPage";
import TratamientosPage from "./views/pages/services/TratamientosPage";
import PresupuestoPage from "./views/pages/presupuestoPages";
import LimpiezaPage from "./views/pages/services/LimpiezaPage";
import PoliticasPage from "./views/pages/PoliticasPage";
import GalleryPage from "./views/pages/GalleryPages.js";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter >
    <Routes>
      <Route path="/servicios/matenimiento" element={<MantenimientoPage />} />
      <Route path="/servicios/construccion" element={<ConstruccionPage />} />
      <Route path="/servicios/forestales" element={<ForestalesPage />} />
      <Route path="/servicios/cerramientos" element={<CerramientosPage />} />
      <Route path="/servicios/tratamientos" element={<TratamientosPage />} />
      <Route path="/servicios/limpieza" element={<LimpiezaPage />} />
      <Route path="/presupuesto" element={<PresupuestoPage />} />
      <Route path="/politica-de-gestion" element={<PoliticasPage />} />
      <Route path="/galeria" element={<GalleryPage />} />
      <Route path="/" element={<Index />} />
    </Routes>
  </BrowserRouter>,
);

import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import TransparentFooter from "../../components/Footers/TransparentFooter";
import { NavbarCollapse } from "../../lib/navbarCollapse";
import pdf from "../../assets/pdfs/politica.pdf";

// core components
export default class PoliticasPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <NavbarCollapse />
                <div className="wrapper">
                    <div className="section section-about-us">
                        <Container>
                            <Row>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview ">
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center politicas-title">
                                        POLÍTICA INTEGRADA DE GESTION{" "}
                                    </h2>
                                    <embed src={pdf} type="application/pdf" width="100%" height="900px" />
                                </div>
                            </div>
                        </Container>
                    </div>
                    <CookieConsent buttonText="Ok!">
                        This website does not use cookies, only those generated
                        by Youtube.
                    </CookieConsent>
                    <TransparentFooter />
                </div>
            </>
        );
    }
}

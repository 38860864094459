import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import jardin from '../../../assets/img/diseno/diseno3.jpg'

// core components
import ConstruccionPageHeader from "../../../components/Headers/ConstruccionPageHeader";
import TransparentFooter from "../../../components/Footers/TransparentFooter";
import { NavbarCollapse } from "../../../lib/navbarCollapse";
// import MyGallery from "../../../components/myComponents/gallery";

export default class ConstruccionPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <NavbarCollapse />
                <div className="wrapper">
                    <ConstruccionPageHeader />
                    <div className="section section-about-us">
                        <Container>
                            <Row>
                                <Col
                                    className="ml-auto mr-auto text-center"
                                    md="8"
                                >
                                    <h5 className="description">
                                        <b>
                                            Un buen diseño nos permitirá
                                            visualizar nuestro proyecto
                                            anticipadamente y permitirá una
                                            mejor gestión futura de nuestros
                                            recursos económicos, optimizar
                                            tiempo de ejecución y mejorar la
                                            calidad de obra.
                                        </b>
                                    </h5>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview ">
                                <Row>
                                    <Col md="6">
                                        <div
                                            className="image-container image-left"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/diseno/diseno2.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                        <div className="section-story-overview "></div>
                                    </Col>
                                    <Col md="5">
                                        <div
                                            className="image-container image-right"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/diseno/diseno1.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview "></div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            El mantenimiento de jardines no debe
                                            ser improvisado, como ejemplo, la
                                            planificación de las tareas a
                                            realizar en previsión a época de
                                            lluvias ayudará a la salud de
                                            nuestro jardín y a obtener
                                            excelentes resultados en su cuidado.
                                            O en caso de exceso de lluvias,
                                            especialmente si el drenaje del
                                            terreno no es el adecuado, el agua
                                            puede quedar estancada demasiado
                                            tiempo con consecuencias negativas
                                            para el jardín. La fertilización y
                                            erradicación de plagas forman parte
                                            del mantenimiento de jardines. El
                                            mantenimiento de jardines deber
                                            realizarse todo el año, solo así
                                            podremos disfrutar de nuestros
                                            jardines durante todo el año. Un
                                            buen diseño nos permitirá visualizar
                                            nuestro proyecto anticipadamente y
                                            permitirá una mejor gestión futura
                                            de nuestros recursos económicos,
                                            optimizar tiempo de ejecución y
                                            mejorar la calidad de obra. En el
                                            diseño de jardines no solo es
                                            importante la elección de las
                                            plantas más adecuadas, atendiendo a
                                            factores como las características
                                            botánicas de la especie, tipo de
                                            suelo, modalidad y cantidad de riego
                                            necesario, crecimiento futuro de la
                                            planta y sus cuidados, sino también
                                            la integración de otros accesorios
                                            como escaleras, fuentes o piscinas,
                                            sin perder de vista la integración
                                            de otros componentes imprescindibles
                                            como instalaciones de riego,
                                            albañilería, iluminación,
                                            mobiliario…
                                        </p>
                                    </Col>
                                </Row>
                                        <div className="separator separator-primary"></div>
                                <div className="section-story-overview "></div>
                                <Row>
                                    <Col md="12">
                                        <img src={jardin} alt='jardin'/>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <MyGallery/> */}
                    <CookieConsent
                buttonText="Ok!"
                >
                    This website does not use cookies, only those generated by
                    Youtube.
                </CookieConsent>
                    <TransparentFooter />
                </div>
            </>
        );
    }
}

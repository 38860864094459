import React from 'react'
import IndexNavbar from '../components/Navbars/IndexNavbar';

export function NavbarCollapse() {
  React.useEffect(() => {
    document.body.classList.add("construccion-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("construccion-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <div>
      <IndexNavbar />
    </div>
  );
}
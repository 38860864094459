import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import TransparentFooter from "../../../components/Footers/TransparentFooter";
import { NavbarCollapse } from "../../../lib/navbarCollapse";
import LimpiezaPageHeader from "../../../components/Headers/LimpiezaPageHeader";

// core components
export default class LimpiezaPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <NavbarCollapse />
                <div className="wrapper">
                    <LimpiezaPageHeader />
                    <div className="section section-about-us">
                        <Container>
                            <Row>
                                <Col
                                    className="ml-auto mr-auto text-center"
                                    md="8"
                                >
                                    <h5 className="description moreWeight">
                                        Grupal Medio Ambiente está totalmente
                                        comprometido con su personal y sus
                                        clientes en la protección del medio
                                        ambiente, haciendo un adecuado uso de
                                        productos químicos para conseguir una
                                        limpieza impecable y un alto grado de
                                        higiene.
                                    </h5>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview ">
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center">
                                        Comunidades de Propietarios y
                                        Urbanizaciones{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="6">
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/limpieza/comunidad.jpeg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                    <Col md="5">
                                        <p className="marginTop">
                                            Realizamos todos los servicios
                                            necesarios para el mantenimiento de
                                            las comunidades de propietarios,
                                            ofrecemos servicios de limpieza
                                            personalizados para las zonas
                                            comunes, servicios auxiliares de
                                            conserjería, supervisión,
                                            mantenimiento y jardinería para que
                                            nuestros clientes tengan un alto
                                            grado de confort al regresar a sus
                                            hogares. Para ello, contamos con un
                                            equipo de personas, formadas para la
                                            gestión de comunidades,
                                            especialistas en limpieza, pintura y
                                            conserjerías.
                                        </p>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center">
                                        Hoteles y Grandes Superficies{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="6">
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/limpieza/hoteles.jpeg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                    <Col md="5">
                                        <p className="marginTop">
                                            Nuestros servicios de limpieza en
                                            grandes superficies y centros
                                            comerciales, garantizan el bienestar
                                            y confort para sus clientes,
                                            adaptándonos con flexibilidad tanto
                                            en horarios como en cargas de
                                            trabajo según las necesidades de
                                            cada centro.
                                        </p>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center">
                                        Centros Educativos{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="6">
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/limpieza/centro.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                    <Col md="5">
                                        <p className="marginTop">
                                            Teniendo en cuenta el volumen y
                                            características de los alumnos, las
                                            instalaciones sanitarias y la
                                            tipología del edificio para elaborar
                                            los procedimientos adecuados con el
                                            fin de garantizar la perfecta
                                            salubridad de los centros. En
                                            periodos de vacaciones se realizarán
                                            actividades especiales de
                                            mantenimiento, para facilitar el
                                            inicio de la actividad.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <CookieConsent buttonText="Ok!">
                        This website does not use cookies, only those generated
                        by Youtube.
                    </CookieConsent>
                    <TransparentFooter />
                </div>
            </>
        );
    }
}

import React from "react";
import CookieConsent from "react-cookie-consent";

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import IndexHeader from "../components/Headers/IndexHeader.js";

// sections for this page
import Images from "./index-sections/Images.js";
import BasicElements from "./index-sections/BasicElements.js";
import Carousel from "./index-sections/Carousel.js";
import TransparentFooter from "../components/Footers/TransparentFooter";

function Index() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <IndexHeader />
                <div className="main">
                    <Images />
                    <Carousel />
                    <BasicElements />

                    <div className="section-story-overview ">
                        <h2 className="ml-auto mr-auto text-center marginTitleYoutube">
                            <b>Nuestro equipo trabajando</b>{" "}
                        </h2>
                    </div>
                    <div className="iframe">
                        <iframe
                            className="ifram"
                            title='GrupalMedioambiente'
                            src="https://www.youtube.com/embed/XEk-uthMqvU"
                            width="100%"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
                <CookieConsent
                buttonText="Ok!"
                >
                    This website does not use cookies, only those generated by
                    Youtube.
                </CookieConsent>
                <TransparentFooter />
            </div>
        </>
    );
}

export default Index;

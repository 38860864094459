/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function TransparentFooter() {
  return (
    <img
      alt="..."
      className="cespedFooter"
      src={require("../../assets/img/cespedFooter.png")}
    ></img>
  );
}

export default TransparentFooter;

import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import TransparentFooter from "../../../components/Footers/TransparentFooter";
import CerramientosPageHeader from "../../../components/Headers/CerramientosPageHeader";
import { NavbarCollapse } from "../../../lib/navbarCollapse";
import ImageGallery from "react-image-gallery";

// import MyGallery from "components/myComponents/gallery";

const images = [];

const importAll = (r) => {
    return r.keys().map(r);
};

const arr = importAll(
    require.context(
        "../../../assets/img/galeria/cerramientos",
        false,
        /\.(png|jpe?g)$/
    )
);

for (const image of arr) {
    images.push({
        original: image,
        thumbnail: image,
    });
}

class MyGalleryCerramiento extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <div className="section section-about-us">
                    <div className="separator separator-primary"></div>
                    <div className="section-story-overview "></div>
                    <Container>
                        <Row>
                            <Col>
                                <div className="imageGallery">
                                    <ImageGallery
                                        items={images}
                                        lazyLoad={true}
                                        showPlayButton={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default class CerramientosPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <NavbarCollapse />
                <div className="wrapper">
                    <CerramientosPageHeader />
                    <div className="section section-about-us">
                        <Container>
                            <Row>
                                <Col
                                    className="ml-auto mr-auto text-center"
                                    md="8"
                                >
                                    <h5 className="description">
                                        Montamos todo tipo de vallados
                                        metálicos. Le asesoramos sin compromiso
                                        sobre los tipos de materiales y vallados
                                        que mejor se acomoden a lo que usted
                                        necesite. Tipos de cerramientos: o
                                        Vallado de simple torsión, galvanizada o
                                        plastificada o Vallado de malla ganadera
                                        o Vallado cinegético o Vallado con malla
                                        plegada o Pistas deportivas o
                                        Cerramientos opacos o Malla
                                        electrosoldada o Mallazo de
                                        reforestación y plantaciones o Alambre
                                        de espino o Gaviones o Instalación de
                                        puertas y cancelas
                                    </h5>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview ">
                                <Row>
                                    <Col md="6">
                                        <div
                                            className="image-container image-left"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/cerramientos/cerramientos1.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                        <div className="section-story-overview "></div>
                                    </Col>
                                    <Col md="5">
                                        <div
                                            className="image-container image-right"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/cerramientos/cerramientos5.jpeg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                            </div>
                        </Container>
                    </div>
                    {/* <MyGalleryCerramiento /> */}
                    <CookieConsent buttonText="Ok!">
                        This website does not use cookies, only those generated
                        by Youtube.
                    </CookieConsent>

                    <TransparentFooter />
                </div>
            </>
        );
    }
}

import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";


// reactstrap components
import {
    Container,
    Row,
    Col,
    FormFeedback,
    Modal,
    ModalBody,
    Button,
} from "reactstrap";

//icons
import success from "../../assets/icons/succes.png";
import fail from "../../assets/icons/fail.png";

// core components
import TransparentFooter from "../../components/Footers/TransparentFooter";
import { GetData } from "../../lib/getdata";
import IndexNavbarMonoColor from "../../components/Navbars/IndexNavbarMonoColor";

function NavbarCollapse() {
    React.useEffect(() => {
        document.body.classList.add("construccion-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("construccion-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <div>
            <IndexNavbarMonoColor />
        </div>
    );
}

const ModalBudget = ({ props }) => {
    const [modal2, setModal2] = React.useState(true);

    const { sending, correct } = props;

    return (
        <div>
            <Modal
                modalClassName="modal-mini modal-info"
                toggle={() => setModal2(true)}
                isOpen={modal2}
            >
                {!sending ? (
                    <ModalBody className="spinnerMe">
                        <div
                            className="spinner-border text-success"
                            role="status"
                        ></div>
                        <p className="">Enviando...</p>
                    </ModalBody>
                ) : correct ? (
                    <ModalBody className="spinnerMe">
                        <img src={success} alt="success" width="50" />
                        <p className="">Correo enviando!</p>
                        <Button
                            className="btn-neutral"
                            color="link"
                            type="button"
                            onClick={() => {
                                setModal2(false);
                            }}
                        >
                            OK
                        </Button>
                    </ModalBody>
                ) : (
                    <ModalBody className="spinnerMe">
                        <img src={fail} alt="fail" width="50" />
                        <p className="">
                            Algo ha ido mal, por favor intentalo otra vez
                        </p>
                        <Button
                            className="btn-neutral"
                            color="link"
                            type="button"
                            onClick={() => setModal2(false)}
                        >
                            OK
                        </Button>
                    </ModalBody>
                )}
            </Modal>
        </div>
    );
};

export default class PresupuestoPage extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            mail: "",
            bussines: "",
            description: "",
            error: null,
            valid: null,
            modal: {
                loading: false,
                sending: false,
                correct: false,
            },
        };
    }

    handleName(e) {
        this.setState({ name: e.target.value });
    }

    handleMail(e) {
        this.setState({ mail: e.target.value });
    }

    handleBussines(e) {
        this.setState({ bussines: e.target.value });
    }

    handleDescription(e) {
        this.setState({ description: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        //eslint-disable-next-line
        const regexp = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!this.state.name) return this.setState({ error: { name: true } });
        if (!this.state.mail || !regexp.test(this.state.mail)) {
            return this.setState({
                error: { mail: true },
                valid: { mail: true },
            });
        }

        if (!this.state.bussines)
            return this.setState({ error: { bussines: true } });
        if (!this.state.description || this.state.description.length <= 19) {
            return this.setState({ error: { description: true } });
        }

        this.setState({
            valid: {
                name: true,
                mail: true,
                bussines: true,
                description: true,
            },
            modal: {
                loading: true,
            },
        });

        GetData.sendEmail({
            name: this.state.name,
            email: this.state.mail,
            subject: this.state.bussines,
            message: this.state.description,
        })
            .then(() => {
                this.setState({
                    modal: {
                        sending: true,
                        loading: true,
                        correct: true,
                    },
                });
                setTimeout(() => {
                    this.setState({
                        modal: {
                            sending: false,
                            loading: false,
                            correct: false,
                        },
                    });
                }, 6000);
            })
            .catch((err) => {
                this.setState({
                    modal: {
                        sending: true,
                        loading: true,
                        correct: false,
                    },
                });
                console.log("something was wrong! :( ...", err);
            });
    }

    render() {
        const { error, valid, modal } = this.state;

        return (
            <>
                <NavbarCollapse />
                <div className="wrapper">
                    <div className="section section-about-us">
                        <div className="separator separator-primary"></div>
                        <div className="section-story-overview "></div>
                        <Container>
                            <Row className="titlePresupuesto">
                                <h2 className="title">
                                    Contacta con nosotros{" "}
                                </h2>
                                <h4 className="title">
                                    Te resolveremos cualquier duda con la mayor
                                    brevedad posible{" "}
                                </h4>
                            </Row>
                            <Row>
                                <Col className="center mx-auto">
                                    <div>
                                        <img
                                            src={require("../../assets/img/presupuesto/central.jpg")}
                                            alt="contactanos"
                                        ></img>
                                    </div>
                                </Col>
                                <Col className="col-presupuesto">
                                    <div className="form-row justify containerInput">
                                        <div className="mb-3 withFormDiv">
                                            <label className="labelWigth">
                                                Nombre
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Escribe aqui tu nombre..."
                                                className={`form-control rounded ${
                                                    error?.name
                                                        ? "is-invalid"
                                                        : valid?.name
                                                        ? "is-valid"
                                                        : ""
                                                }`}
                                                onChange={(e) =>
                                                    this.handleName(e)
                                                }
                                                required
                                            />
                                            <FormFeedback>
                                                El nombre no debe estar vacio
                                            </FormFeedback>
                                        </div>
                                    </div>
                                    <div className="form-row justify containerInput">
                                        <div className="withFormDiv mb-3">
                                            <label>Correo Electronico</label>
                                            <input
                                                type="text"
                                                placeholder="ejemplo@correo.com"
                                                className={`form-control rounded
                         ${
                             error?.mail
                                 ? "is-invalid"
                                 : valid?.mail
                                 ? "is-valid"
                                 : ""
                         }
                        `}
                                                onChange={(e) =>
                                                    this.handleMail(e)
                                                }
                                            />
                                            <FormFeedback>
                                                El correo electronico debe tener
                                                un formato valido
                                            </FormFeedback>
                                        </div>
                                    </div>
                                    <div className="form-row justify containerInput">
                                        <div className="withFormDiv mb-3">
                                            <label>
                                                Empresa / Organizacion
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Bussines S.A"
                                                className={`form-control rounded ${
                                                    error?.bussines
                                                        ? "is-invalid"
                                                        : valid?.bussines
                                                        ? "is-valid"
                                                        : ""
                                                }`}
                                                onChange={(e) =>
                                                    this.handleBussines(e)
                                                }
                                                required
                                            />
                                            <FormFeedback>
                                                La empresa no debe estar vacio
                                            </FormFeedback>
                                        </div>
                                    </div>
                                    <div className="form-row justify containerInput">
                                        <div className="withFormDiv heigthFormDiv mb-3">
                                            <label>Descripcion</label>
                                            <textarea
                                                type="text"
                                                placeholder="Describenos aqui lo que necesitas..."
                                                className={`form-control rounded heigthFormDiv ${
                                                    error?.description
                                                        ? "is-invalid"
                                                        : valid?.description
                                                        ? "is-valid"
                                                        : ""
                                                }`}
                                                onChange={(e) =>
                                                    this.handleDescription(e)
                                                }
                                                required
                                            />
                                            <FormFeedback>
                                                La descripcion debe tener al
                                                menos 20 caracteres...
                                            </FormFeedback>
                                        </div>
                                    </div>
                                    {modal.loading ? (
                                        <ModalBudget props={modal} />
                                    ) : (
                                        <div></div>
                                    )}
                                    <button
                                        className="btn btn-primary mx-auto marginTop"
                                        onClick={(e) => this.handleSubmit(e)}
                                    >
                                        Enviar
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <CookieConsent
                buttonText="Ok!"
                >
                    This website does not use cookies, only those generated by
                    Youtube.
                </CookieConsent>
                    <TransparentFooter />
                </div>
            </>
        );
    }
}

import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import ForestalesPageHeader from "../../../components/Headers/ForestalesPageHeader ";
import TransparentFooter from "../../../components/Footers/TransparentFooter";
import { NavbarCollapse } from "../../../lib/navbarCollapse";

//imgs
import repoblaciones from "../../../assets/img/forestales/repoblaciones.jpg";
import aprovechamiento from "../../../assets/img/forestales/aprovechamiento.jpg";
import carretera from "../../../assets/img/forestales/carretera.jpg";
import cinergetica from "../../../assets/img/forestales/cinergetica.jpeg";
import cortafuegos from "../../../assets/img/forestales/cortafuegos.jpg";
import degradadas from "../../../assets/img/forestales/degradadas.jpeg";
import ferreas from "../../../assets/img/forestales/ferreas.jpg";
import pistas from "../../../assets/img/forestales/pistas.jpg";
import MyGallery from "../../../components/myComponents/gallery";

export default class ForestalesPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <NavbarCollapse />
                <div className="wrapper">
                    <ForestalesPageHeader />
                    <div className="section section-about-us">
                        <Container>
                            <Row>
                                <Col
                                    className="ml-auto mr-auto text-center"
                                    md="8"
                                >
                                    <h5 className="description">
                                        Uno de nuestros principales objetivos es
                                        mejorar la calidad ambiental mediante la
                                        oferta de servicios, poniendo a su
                                        disposición las mejores técnicas
                                        disponibles y el compromiso de buenas
                                        prácticas para el desarrollo de trabajos
                                        de índole forestal y de jardinería. De
                                        esta manera podemos convertir nuestro
                                        medio natural en una fuente de riqueza a
                                        través del mantenimiento del mismo y del
                                        correcto aprovechamiento de los recursos
                                        que éste nos ofrece.
                                    </h5>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview ">
                                <Row>
                                    <Col md="6">
                                        <div
                                            className="image-container image-left"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/forestales/forestales1.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                        <div className="section-story-overview "></div>
                                    </Col>
                                    <Col md="5">
                                        <div
                                            className="image-container image-right"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/forestales/forestales2.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Repoblaciones forestales{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            Se puede definir como el conjunto de
                                            técnicas que se necesitan aplicar
                                            para crear una masa forestal,
                                            formada por especies vegetales
                                            leñosas (árboles o arbustivas), que
                                            sea estable con el medio, en un
                                            terreno cuya vegetación actual es
                                            ineficaz en mayor o menor grado
                                            según el uso asignado al territorio,
                                            y que adoptando las características
                                            deseadas, cumpla con los fines que
                                            de ella se demandan.
                                        </p>
                                        <p>
                                            Si la masa forestal es estable en el
                                            medio, implica que su supervivencia,
                                            desarrollo y reproducción no
                                            dependerán de la intervención humana
                                            constante o intensa, aunque sí se
                                            deben realizar tratamientos
                                            silvícolas esporádicos como control
                                            de plagas y enfermedades, podas,
                                            clareos…
                                        </p>
                                        <p className="marginTop">
                                            Entre los objetivos de la
                                            repoblación forestal se encuentran
                                            aquellos enfocados a la producción
                                            (materia prima o bienes directos) y
                                            los objetivos protectores con la
                                            obtención de beneficios indirectos
                                            derivados de la simple existencia de
                                            la masa como protección del suelo o
                                            su defensa frente a la erosión
                                            hídrica o eólica, conservación y
                                            mejora de las condiciones de
                                            desarrollo de la vida silvestre…
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img src={repoblaciones} alt="podas" />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Aprovechamientos forestales{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            Son la fase del ciclo productivo del
                                            monte, en la que se ejecuta las
                                            operaciones necesarias para extraer
                                            los productos forestales del monte y
                                            ser transportados a su lugar de
                                            utilización.
                                        </p>
                                        <p>
                                            Los principales aprovechamientos
                                            son: madera y leña, corcho y resina,
                                            biomasa forestal, aprovechamiento
                                            cinegético y piscícola, frutos,
                                            ramón y pastos, plantas aromáticas y
                                            medicinales, hongos, miel y
                                            productos apícolas, aprovechamientos
                                            minerales y recreativos.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img
                                            src={aprovechamiento}
                                            alt="aprovechamiento"
                                        />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Tratamientos silvícolas{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="6" className="sivicolasWrapper">
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/forestales/forestales3.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                    <Col md="5">
                                        <p>
                                            Permiten favorecer la regeneración
                                            forestal, eliminar el estrato
                                            arbustivo y disminuir el riesgo de
                                            incendios.
                                        </p>
                                        <p>
                                            En esta labor se lleva a cabo
                                            diferentes acciones como: desbroces
                                            manuales y mecanizados, podas,
                                            rozas, clareos…
                                        </p>
                                        <p className="marginTop">
                                            El objetivo de este tratamiento
                                            dependerá en todo momento de la zona
                                            sobre la que se actúe, de la
                                            orografía, el estado del terreno,
                                            etc.
                                        </p>

                                        <p className="marginTop">
                                            Talar un árbol requiere un análisis
                                            exhaustivo de distintos factores:
                                            entorno, tipo de árbol, tipo de
                                            maquinaria necesaria a emplear,
                                            operarios cualificados, gestión de
                                            residuos.
                                        </p>
                                        <p className="marginTop">
                                            En algunos casos puede ser necesario
                                            cortar el tráfico o solicitar
                                            autorización a los organismos
                                            públicos (ayuntamientos, etc.).
                                        </p>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview"></div>
                                <Row>
                                    <h2 className="ml-auto mr-auto text-center">
                                        Mantenimiento de líneas eléctricas{" "}
                                    </h2>
                                    <Col md="6">
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/forestales/desbroce.jpeg") +
                                                    ")",
                                            }}
                                        ></div>
                                        <div
                                            className="image-container image-right lessPadingImage2"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/forestales/desbroce2.jpeg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                    <Col md="5">
                                        <p className="paragraphElectric">
                                            Las líneas eléctricas en muchos de
                                            sus tramos atraviesan masas
                                            forestales, por lo que hay que
                                            mantener una distancia entre éstas y
                                            la vegetación lateral y subyacente,
                                            con el fin de evitar problemas en la
                                            continuidad del suministro
                                            eléctrico, así como, garantizar la
                                            seguridad de las masas arbóreas,
                                            evitando incendios.
                                        </p>
                                        <p>
                                            En esta labor se lleva a cabo
                                            diferentes acciones como: desbroces
                                            manuales y mecanizados, podas,
                                            rozas, clareos…
                                        </p>
                                        <p className="marginTop">
                                            El objetivo de este tratamiento
                                            dependerá en todo momento de la zona
                                            sobre la que se actúe, de la
                                            orografía, el estado del terreno,
                                            etc.
                                        </p>

                                        <p className="marginTop">
                                            Talar un árbol requiere un análisis
                                            exhaustivo de distintos factores:
                                            entorno, tipo de árbol, tipo de
                                            maquinaria necesaria a emplear,
                                            operarios cualificados, gestión de
                                            residuos.
                                        </p>
                                        <p className="marginTop">
                                            En algunos casos puede ser necesario
                                            cortar el tráfico o solicitar
                                            autorización a los organismos
                                            públicos (ayuntamientos, etc.).
                                        </p>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Cortafuegos y fajas auxiliares{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            En Grupal Medio Ambiente entendemos
                                            la importancia de la prevención de
                                            incendios, por eso la apertura y
                                            mantenimiento de cortafuegos y fajas
                                            auxiliares son fundamentales para
                                            esta labor.
                                        </p>
                                        <p>
                                            Dichas actuaciones se realizan en la
                                            masa para modificar los modelos de
                                            combustible existentes y crear
                                            discontinuidades lineales, tanto en
                                            vertical (podas) como en horizontal
                                            (desbroces) para reducir así el
                                            grado de peligro.
                                        </p>
                                        <p>
                                            Estas actividades se suelen
                                            acompañar con tratamientos
                                            culturales (resalveos y selección de
                                            brotes, clareos, etc.) para lograr
                                            una espesura completa, así como
                                            también la implantación, siempre con
                                            los debidos condicionantes
                                            ecológicos y presupuestarios, de
                                            especies de menor inflamabilidad
                                            (ripícolas, halófilas). Este tipo de
                                            actuaciones suelen tener carácter
                                            lineal y perimetral.
                                        </p>
                                        <p>
                                            Hay un dicho en nuestro sector que
                                            dice:
                                            <b>
                                                {" "}
                                                <i>
                                                    "Los cortafuegos no paran
                                                    incendios, pero los
                                                    incendios paran en los
                                                    cortafuegos..."
                                                </i>
                                            </b>
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img
                                            src={cortafuegos}
                                            alt="cortafuegos"
                                        />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Caminos y pistas forestales{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            Su finalidad consiste en facilitar
                                            la vigilancia y conservación de los
                                            montes, así como el aprovechamiento
                                            de los terrenos forestales
                                            (hidráulicos, cinegéticos,
                                            forestales y otros similares),
                                            siendo de utilidad también para la
                                            prevención y extinción de incendios.
                                        </p>
                                        <p>
                                            También conocidos como caminos
                                            rurales, son las vías de
                                            comunicación que cubren las
                                            necesidades de tráfico generado en
                                            áreas rurales que son se escasa
                                            circulación.
                                        </p>
                                        <p>
                                            Las pistas forestales son vías de
                                            comunicación en áreas forestales, en
                                            concreto por montes, cuya anchura y
                                            trazado permiten la circulación de
                                            vehículos de cuatro ruedas, pero que
                                            no se clasifican como carreteras y
                                            que suelen estar sin asfaltar.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img
                                            src={pistas}
                                            alt="pistas forestales"
                                        />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Mantenimiento de líneas férreas{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            La conservación de las
                                            infraestructuras y mantenimiento de
                                            líneas férreas mejoran el
                                            funcionamiento y la seguridad de las
                                            mismas.
                                        </p>
                                        <p>
                                            Grupal Medio Ambiente ejecuta obras
                                            de conservación, mejora y
                                            saneamiento de masas forestales
                                            mediante labores silvícolas de
                                            clareo, poda, desbroce, eliminación
                                            de restos y mantenimiento de líneas
                                            férreas.
                                        </p>
                                        <p>
                                            Las pistas forestales son vías de
                                            comunicación en áreas forestales, en
                                            concreto por montes, cuya anchura y
                                            trazado permiten la circulación de
                                            vehículos de cuatro ruedas, pero que
                                            no se clasifican como carreteras y
                                            que suelen estar sin asfaltar.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img
                                            src={ferreas}
                                            alt="Lineas ferreas"
                                        />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Mantenimiento de carreteras y autovías{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            Preparación, ajardinamiento y
                                            mantenimiento de carreteras,
                                            autovías y autopistas. Creación y
                                            mantenimiento de áreas de descanso.
                                            Desbroce de márgenes y medianas en
                                            carreteras convencionales, autovías
                                            y autopistas. Mantenimiento de setos
                                            en medianas de autovías y
                                            autopistas. Podas y talas de
                                            arboleda en márgenes de carreteras
                                            convencionales, autovías y
                                            autopistas. Plantaciones en zonas
                                            verdes, áreas de descanso, taludes,
                                            márgenes y medianas de carreteras
                                            convencionales, autovías y
                                            autopistas.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img src={carretera} alt="carreteras" />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Mantenimiento de fincas rústicas,
                                        forestales y cinegéticas{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            Los servicios que ofrecemos abarcan
                                            desde cerramientos de fincas,
                                            cerramientos impermeables de
                                            seguridad para carreteras, vías y
                                            canales, construcción y reparación
                                            de vallados de todo tipo
                                            (cinegéticas, ganaderas, simple
                                            torsión, electrosoldada, de
                                            madera…), pasos de fauna,
                                            repoblación vegetal, desbroces de
                                            vegetación, podas, talas y clareos
                                            de arboleda, arreglos de caminos y
                                            carriles…
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img src={cinergetica} alt="podas" />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Recuperación ambiental de áreas
                                        degradadas{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            Son prácticas utilizadas en suelos
                                            susceptibles a la erosión y
                                            degradación que favorecen la
                                            desertificación, uno de los
                                            principales problemas
                                            socioeconómicos actuales de la
                                            población.
                                        </p>
                                        <p>
                                            El objetivo consiste en la mejora de
                                            los hábitats naturales y
                                            seminaturales en el entorno natural,
                                            para logar la conservación de suelos
                                            para su productividad tanto
                                            silvícola como forestal.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img src={degradadas} alt="podas" />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview " />
                            </div>
                        </Container>
                    </div>
                    {/* <MyGallery /> */}
                    <CookieConsent buttonText="Ok!">
                        This website does not use cookies, only those generated
                        by Youtube.
                    </CookieConsent>
                    <TransparentFooter />
                </div>
            </>
        );
    }
}

import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// core components

function BasicElements() {
    return (
        <>
            <div className="section section-basic" id="basic-elements">
                <div className="section section-about-us">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">¿Quienes somos?</h2>
                                <h5 className="description">
                                    Somos una empresa de nueva creación y en
                                    plena expansión, dedicada a los sectores de
                                    la <b>Jardinería, Forestal y Limpieza</b>.
                                    Actualmente desarrollamos nuestros servicios
                                    principalmente en la Comunidad Autónoma de
                                    Andalucía, concretamente{" "}
                                    <b>
                                        en las provincias de Córdoba, Málaga,
                                        Cádiz y Sevilla.
                                    </b>
                                </h5>
                            </Col>
                        </Row>
                        <div className="separator separator-primary"></div>
                        <div className="section-story-overview">
                            <Row>
                                <Col md="6" className="margin-left">
                                    <div
                                        className="image-container image-left"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("../../assets/img/login.jpg") +
                                                ")",
                                        }}
                                    >
                                        <p className="blockquote blockquote-info">
                                            "He reducido el Mundo a mi jardín y
                                            ahora veo la intensidad de todo lo
                                            que existe" <br></br>
                                            <br></br>
                                            <small>
                                                - JOSÉ ORTEGA Y GASSET
                                            </small>
                                        </p>
                                    </div>
                                    <div
                                        className="image-container"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("../../assets/img/bg3.jpg") +
                                                ")",
                                        }}
                                    ></div>
                                </Col>
                                <Col md="5">
                                    <div
                                        className="image-container image-right"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("../../assets/img/bg1.jpeg") +
                                                ")",
                                        }}
                                    ></div>
                                    <h3>
                                        Contamos con operarios altamente
                                        cualificados, formados y con gran
                                        ilusión por su trabajo.
                                    </h3>
                                    <p>
                                        GRUPAL nace para complacer a los
                                        clientes más exigentes y esa será
                                        siempre nuestro mayor inquietud, dar un
                                        servicio exclusivo y de primera calidad.
                                    </p>
                                    <p>
                                        Ofrecemos <b>SERVICIOS DE JARDINERÍA</b>{" "}
                                        para Comunidades de Propietarios,
                                        Urbanizaciones, Jardines Privados,
                                        Campos de Golf, Club Deportivos,
                                        Colegios, Tratamientos Herbicidas,
                                        Instalaciones de Riegos por Aspersión o
                                        por Goteo y un largo etc..
                                    </p>
                                    <p>
                                        <b>TRABAJOS FORESTALES</b>, como
                                        Selvicultura (Talas, Podas y Clareos de
                                        Arboledas ) Desbroces de Fincas
                                        Forestales, Cinegéticas, Parcelas,
                                        Caminos, Cortafuegos, Carreteras, vías
                                        de tren, estaciones de trenes, Parques
                                        Eólicos, Huertos Fotovoltaicos,
                                        Perímetros de Comunidades y
                                        Urbanizaciones, Mantenimientos de Líneas
                                        Eléctricas
                                    </p>
                                    <p>
                                        <b>
                                            SERVICIOS CERRAMIENTOS, VALLAS,
                                            CERCADOS, ALAMBRADAS{" "}
                                        </b>
                                        , todo tipo vallas cinegéticas,
                                        cerramientos ganaderos, vallados
                                        electrosoldados (tipo hércules),
                                        cerramientos parcelas rústicas,
                                        polígonos, empresas, cercados con mallas
                                        simple torsión, cerramientos para
                                        carreteras, autovías, autopistas, vías
                                        férreas, parques eólicos y
                                        fotovoltaicos,etc..
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="section section-team text-center">
                    <Container>
                        <h2 className="title marginBottom">Nuestros Servicios</h2>
                        <div className="team">
                            <Row className="padding-img">
                                <Col md="4">
                                    <div className="team-player">
                                        <Link
                                            className="enlace"
                                            to="/servicios/matenimiento"
                                        >
                                            <img
                                                className="imgService"
                                                alt="..."
                                                src={require("../../assets/img/servicios/mantenimiento.png")}
                                            ></img>
                                        </Link>
                                        <Link
                                            className="enlace"
                                            to="/servicios/matenimiento"
                                        >
                                            <h4 className="title category text-info titlePadding">
                                                MANTENIMIENTO DE JARDINERIA Y
                                                PISCINAS
                                            </h4>
                                        </Link>

                                        <p className="description">
                                            Intervenimos en todo tipo de
                                            espacios verdes, como jardines
                                            privados y públicos, comunidades de
                                            propietarios, urbanizaciones, campos
                                            deportivos, hoteles, campos de golf,
                                            actuaciones paisajísticas, así como
                                            en la gestión de residuos.
                                        </p>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <Link to="/servicios/construccion">
                                            <img
                                                className="imgService"
                                                alt="..."
                                                src={require("../../assets/img/servicios/diseno.png")}
                                            ></img>
                                        </Link>
                                        <Link
                                            className="enlace"
                                            to="/servicios/construccion"
                                        >
                                            <h4 className="title category text-info enlace titlePadding">
                                                CONSTRUCCION Y DISEÑO DE
                                                JARDINES
                                            </h4>
                                        </Link>

                                        <p className="description">
                                            Un buen diseño nos permitirá
                                            visualizar nuestro proyecto
                                            anticipadamente y permitirá una
                                            mejor gestión futura de nuestros
                                            recursos económicos, optimizar
                                            tiempo de ejecución y mejorar la
                                            calidad de obra
                                        </p>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <Link
                                            className="enlace"
                                            to="/servicios/tratamientos"
                                        >
                                            <img
                                                className="imgService"
                                                alt="..."
                                                src={require("../../assets/img/servicios/fumigar.png")}
                                            ></img>
                                        </Link>

                                        <Link
                                            className="enlace"
                                            to="/servicios/tratamientos"
                                        >
                                            <h4 className="title category text-info titlePadding">
                                                TRATAMIENTOS FITOSANITARIOS Y
                                                HERBICIDAS
                                            </h4>
                                        </Link>
                                        <p className="description">
                                            La utilización de las técnicas y de
                                            las tecnologías adecuadas para la
                                            aplicación de productos
                                            fitosanitarios es un requisito de la
                                            máxima importancia para conseguir la
                                            eficacia deseada en el control de
                                            plagas, enfermedades y malas
                                            hierbas.
                                        </p>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <Link
                                            className="enlace"
                                            to="/servicios/forestales"
                                        >
                                            <img
                                                className="imgService"
                                                alt="..."
                                                src={require("../../assets/img/servicios/forestales.png")}
                                            ></img>
                                        </Link>
                                        <Link
                                            className="enlace"
                                            to="/servicios/forestales"
                                        >
                                            <h4 className="title category text-info titlePadding">
                                                TRABAJOS FORESTALES
                                            </h4>
                                        </Link>

                                        <p className="description">
                                            Uno de nuestros principales
                                            objetivos es mejorar la calidad
                                            ambiental mediante la oferta de
                                            servicios, poniendo a su disposición
                                            las mejores técnicas disponibles y
                                            el compromiso de buenas prácticas
                                            para el desarrollo de trabajos de
                                            índole forestal y de jardinería.
                                        </p>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <Link
                                            className="enlace"
                                            to="/servicios/cerramientos"
                                        >
                                            <img
                                                className="imgService"
                                                alt="..."
                                                src={require("../../assets/img/servicios/cerramientos.png")}
                                            ></img>
                                        </Link>
                                        <Link
                                            className="enlace"
                                            to="/servicios/cerramientos"
                                        >
                                            <h4 className="title category text-info titlePadding">
                                                CERRAMIENTOS
                                            </h4>
                                        </Link>

                                        <p className="description">
                                            SERVICIOS CERRAMIENTOS, VALLAS,
                                            CERCADOS, ALAMBRADAS de todo tipo
                                            vallas cinegéticas, cerramientos
                                            ganaderos, vallados electrosoldados
                                            (tipo hércules), cerramientos
                                            parcelas rústicas, polígonos,
                                            empresas, cercados con mallas simple
                                            torsión, cerramientos para
                                            carreteras, autovías, autopistas,
                                            vías férreas, parques eólicos y
                                            fotovoltaicos, colegios, mallazos
                                            para reforestaciones, etc...
                                        </p>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <Link
                                            className="enlace"
                                            to="/servicios/limpieza"
                                        >
                                            <img
                                                className="imgService"
                                                alt="..."
                                                src={require("../../assets/img/servicios/limpieza.png")}
                                            ></img>
                                        </Link>
                                        <Link
                                            className="enlace"
                                            to="/servicios/limpieza"
                                        >
                                            <h4 className="title category text-info titlePadding">
                                                SERVICIOS GENERALES Y DE
                                                LIMPIEZA
                                            </h4>
                                        </Link>

                                        <p className="description">
                                            para Comunidades de propietarios,
                                            Urbanizaciones, Hoteles, Centros
                                            Comerciales, etc...
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default BasicElements;

import axios from 'axios';


const instance = axios.create({
    baseURL: '',
    timeout: 5000,
    withCredentials: true,
});

export class GetData {

    static errorHandler(e) {
        console.error("GETDATA API ERROR");
        console.error(e);
        throw e;
    }

    static sendEmail(data){
        return instance.post('/budget', data)
        .then(res =>{ console.log(' correo enviado correctamente '); return res.data})
        .catch(GetData.errorHandler)
    }
}


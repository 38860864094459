import React from "react";
import LightGallery from 'lightgallery/react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgAutoplay from 'lightgallery/plugins/autoplay'
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgShare from 'lightgallery/plugins/share';
import lgRotate from 'lightgallery/plugins/rotate';
import './gallery.css'

const images = [];

const importAll = (r) => {
    return r.keys().map(r);
};

const arr = importAll(
    require.context("../../assets/projects", false, /\.(png|jpe?g)$/)
);

for (const image of arr) {
    images.push({
        original: image,
        thumbnail: image,
    });
}

export default class MyGallery extends React.Component {
    render() {
        const onInit = () => {
            console.log('lightGallery has been initialized');
        };

        return (
            <div className="App">
                <LightGallery
                    onInit={onInit}
                    speed={500}
                    plugins={[lgThumbnail, lgZoom, lgAutoplay, lgFullscreen, lgRotate, lgShare]}
                >

                    {images.map((image, index) => {
                        const now = `image - ${Date.now()}`
                        return (
                            <a href={image.original} key={index}>
                                <img className="img-galery" alt={now} src={image.original} />
                            </a>
                        )
                    })}


                </LightGallery>
            </div>
        );
    }
}

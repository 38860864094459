import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";


// reactstrap components
import { Container } from "reactstrap";

// core components
import TransparentFooter from "../../components/Footers/TransparentFooter";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import MyGallery from "../../components/myComponents/gallery";


function NavbarCollapse() {
    React.useEffect(() => {
        document.body.classList.add("construccion-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("construccion-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <div>
            <IndexNavbar />
        </div>
    );
}


export default class GalleryPage extends Component {

    render() {

        return (
            <>
                <NavbarCollapse />
                <div className="wrapper">
                    <div className="section section-about-us">
                        <div className="separator separator-primary"></div>
                        <div className="section-story-overview "></div>
                        <Container>
                            <MyGallery />
                        </Container>
                    </div>
                    <CookieConsent
                        buttonText="Ok!"
                    >
                        This website does not use cookies, only those generated by
                        Youtube.
                    </CookieConsent>
                    <TransparentFooter />
                </div>
            </>
        );
    }
}

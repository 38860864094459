import React from "react";
import { Link } from "react-router-dom";
import twitter from "../../assets/icons/twitter.png";
import Linkedin from "../../assets/icons/linkedin-logo.png";
import pinterest from "../../assets/icons/pinterest-sign.png";
import telefono from "../../assets/icons/telefono.png";
import facebook from "../../assets/icons/facebook.png";
import mail from "../../assets/icons/mail.png";
import budget from "../../assets/icons/budget.png";
import cube from "../../assets/icons/cube.png";


// reactstrap components
import {
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    UncontrolledTooltip,
} from "reactstrap";

function IndexNavbarMonoColor() {
    //eslint-disable-next-line
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 399 ||
                document.body.scrollTop > 399
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 400 ||
                document.body.scrollTop < 400
            ) {
                setNavbarColor("navbar-transparent");
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });
    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar className="fixed-top  navbar" expand="lg" color="info">
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand id="navbar-brand" to="/" tag={Link}>
                            <img
                                src={require("../../assets/img/logo/OnlyLogo.png")}
                                width="40"
                                alt="logo"
                            ></img>
                            &nbsp; GrupalMedioambiente
                        </NavbarBrand>
                        <button
                            className="navbar-toggler navbar-toggler"
                            onClick={() => {
                                document.documentElement.classList.toggle(
                                    "nav-open"
                                );
                                setCollapseOpen(!collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <span className="navbar-toggler-bar top-bar"></span>
                            <span className="navbar-toggler-bar middle-bar"></span>
                            <span className="navbar-toggler-bar bottom-bar"></span>
                        </button>
                    </div>
                    <div className="btn-neutral nav-link rounded">
                        <div>
                            <img
                                src={telefono}
                                alt="telefono"
                                height="20"
                                className="rounded"
                            />
                            <p className="marginTextPhone"> 644 550 345</p>
                        </div>
                        <hr className='hr'></hr>
                        <div>
                            <img
                                src={mail}
                                alt="Mail"
                                height="20"
                                className="rounded mail"
                            />
                            <p className="marginTextPhone">
                                {" "}
                                info@grupalmedioambiente.com
                            </p>
                        </div>
                    </div>
                    <Collapse
                        className="justify-content-end"
                        isOpen={collapseOpen}
                        navbar
                    >
                        <Nav navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle
                                    caret
                                    color="default"
                                    href="#pablo"
                                    nav
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <img
                                        src={cube}
                                        alt="Services"
                                        height="15"
                                        className="rounded mail"
                                    />
                                    <p>Servicios</p>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        to="/servicios/matenimiento"
                                        tag={Link}
                                    >
                                        <i className="now-ui-icons business_chart-pie-36 mr-1"></i>
                                        Mantenimiento de jardinerias y Piscinas
                                    </DropdownItem>
                                    <DropdownItem
                                        to="/servicios/tratamientos"
                                        tag={Link}
                                    >
                                        <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                                        Tratamientos fitosanitarios y herbicidas
                                    </DropdownItem>
                                    <DropdownItem
                                        to="/servicios/construccion"
                                        tag={Link}
                                    >
                                        <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                                        Construccion y diseño de jardines
                                    </DropdownItem>
                                    <DropdownItem
                                        to="/servicios/forestales"
                                        tag={Link}
                                    >
                                        <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                                        Trabajos forestales
                                    </DropdownItem>
                                    <DropdownItem
                                        to="/servicios/cerramientos"
                                        tag={Link}
                                    >
                                        <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                                        Cerramientos
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <a
                                    className="nav-link btn-neutral rounded link-presupuesto"
                                    color="info"
                                    id="upgrade-to-pro"
                                    href="/presupuesto"
                                >
                                    <img
                                        src={budget}
                                        alt="Presupueto"
                                        height="15"
                                        className="rounded mail"
                                    />
                                    <p className="mediaQuery">
                                        Pide Presupuesto
                                    </p>
                                </a>
                                <UncontrolledTooltip target="#upgrade-to-pro">
                                    ¡Te contestaremos lo antes posible!
                                </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="https://twitter.com/grupalmedioamb"
                                    target="_blank"
                                    id="twitter-tooltip"
                                    rel="noreferrer noopener"
                                >
                                    {/* <i className="fab fa-twitter"></i> */}
                                    <img
                                        src={twitter}
                                        alt="Twitter"
                                        height="20"
                                        className="rounded mr"
                                    />
                                    <p className="d-lg-none d-xl-none">
                                        Twitter
                                    </p>
                                </NavLink>
                                <UncontrolledTooltip target="#twitter-tooltip">
                                    Siguenos en Twitter!
                                </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="https://www.linkedin.com/company/grupal-medio-ambiente/"
                                    target="_blank"
                                    id="facebook-tooltip"
                                    rel="noreferrer noopener"
                                >
                                    {/* <i className="fab fa-linkedin"></i> */}
                                    <img
                                        src={Linkedin}
                                        alt="Linkedin"
                                        height="20"
                                        className="rounded mr"
                                    />
                                    <p className="d-lg-none d-xl-none">
                                        Linkedin
                                    </p>
                                </NavLink>
                                <UncontrolledTooltip target="#facebook-tooltip">
                                    Agreganos en LinkedIn!
                                </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="https://www.pinterest.co.uk/grupalmedio/"
                                    target="_blank"
                                    id="pinterest-tooltip"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={pinterest}
                                        alt="Pinterest"
                                        height="20"
                                        className="rounded mr"
                                    />
                                    <p className="d-lg-none d-xl-none">
                                        Pinterest
                                    </p>
                                </NavLink>
                                <UncontrolledTooltip target="#pinterest-tooltip">
                                    Siguenos en pinterest!
                                </UncontrolledTooltip>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    href="https://www.facebook.com/Grupal-Medio-Ambiente-113478313337111"
                                    target="_blank"
                                    id="facebook-tooltip"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={facebook}
                                        alt="Facebook"
                                        height="20"
                                        className="rounded mr"
                                    />
                                    <p className="d-lg-none d-xl-none">
                                        Facebook
                                    </p>
                                </NavLink>
                                <UncontrolledTooltip target="#facebook-tooltip">
                                    Siguenos en Facebook!
                                </UncontrolledTooltip>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default IndexNavbarMonoColor;

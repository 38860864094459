import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import TransparentFooter from "../../../components/Footers/TransparentFooter";
import MatenimietoPageHeader from "../../../components/Headers/MatenimietoPageHeader";
import { NavbarCollapse } from "../../../lib/navbarCollapse";
import podas from "../../../assets/img/mantenimiento/podas.jpeg";
import desbroces from "../../../assets/img/mantenimiento/desbroces.jpeg";
import fumigar from "../../../assets/img/mantenimiento/fumigar.jpg";
import MyGallery from "../../../components/myComponents/gallery";

export default class MantenimientoPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <NavbarCollapse />
                <div className="wrapper">
                    <MatenimietoPageHeader />
                    <div className="section section-about-us">
                        <Container>
                            <Row>
                                <Col
                                    className="ml-auto mr-auto text-center"
                                    md="8"
                                >
                                    <h2 className="title">
                                        SERVICIOS DE JARDINERÍA{" "}
                                    </h2>
                                    <h5 className="description">
                                        En Grupal Medio Ambiente intervenimos en
                                        todo tipo de espacios verdes, como
                                        jardines privados y públicos,
                                        comunidades de propietarios,
                                        urbanizaciones, campos deportivos,
                                        hoteles, campos de golf, actuaciones
                                        paisajísticas, así como en la gestión de
                                        residuos. Mediante la planificación
                                        meticulosa de las labores de trabajo y
                                        su ejecución por operarios competentes,
                                        formados y experimentados, dotados de
                                        equipos y maquinaria adecuada,
                                        conseguimos sacar el máximo partido
                                        estético y funcional a las zonas verdes.
                                        El seguimiento de los trabajos
                                        realizados por parte de nuestro personal
                                        técnico garantiza el nivel de calidad
                                        requerido.
                                    </h5>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview ">
                                <h2 className="ml-auto mr-auto text-center">
                                    Mantenimiento de jardines{" "}
                                </h2>
                            </div>
                            <Row>
                                <Col md="12">
                                    <p>
                                        El mantenimiento de jardines no debe ser
                                        improvisado, como ejemplo, la
                                        planificación de las tareas a realizar
                                        en previsión a época de lluvias ayudará
                                        a la salud de nuestro jardín y a obtener
                                        excelentes resultados en su cuidado. O
                                        en caso de exceso de lluvias,
                                        especialmente si el drenaje del terreno
                                        no es el adecuado, el agua puede quedar
                                        estancada demasiado tiempo con
                                        consecuencias negativas para el jardín.
                                    </p>
                                    <p>
                                        La fertilización y erradicación de
                                        plagas forman parte del mantenimiento de
                                        jardines. El mantenimiento de jardines
                                        deber realizarse todo el año, solo así
                                        podremos disfrutar de nuestros jardines
                                        durante todo el año.
                                    </p>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview ">
                                <Row>
                                    <Col md="6">
                                        <div
                                            className="image-container image-left"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/mantenimiento/mantenimiento3.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                        <div className="section-story-overview "></div>
                                    </Col>
                                    <Col md="5">
                                        <div
                                            className="image-container image-right"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/mantenimiento/mantenimiento5.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview "></div>
                                <Row>
                                    <Col md="6">
                                        <div
                                            className="image-container lessPadingImage"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/mantenimiento/mantenimiento4.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                        <div
                                            className="image-container image-right lessPadingImage2"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/mantenimiento/mantenimiento2.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                    <Col md="5">
                                        <h3>
                                            El mantenimiento de jardines no debe
                                            ser improvisado
                                        </h3>
                                        <p>
                                            La planificación de las tareas a
                                            realizar en previsión a época de
                                            lluvias ayudará a la salud de
                                            nuestro jardín y a obtener
                                            excelentes resultados en su cuidado.
                                            O en caso de exceso de lluvias,
                                            especialmente si el drenaje del
                                            terreno no es el adecuado, el agua
                                            puede quedar estancada demasiado
                                            tiempo con consecuencias negativas
                                            para el jardín. La fertilización y
                                            erradicación de plagas forman parte
                                            del mantenimiento de jardines.
                                        </p>
                                        <p>
                                            La fertilización y erradicación de
                                            plagas forman parte del
                                            mantenimiento de jardines.
                                        </p>
                                        <p className="marginTop">
                                            El mantenimiento de jardines deber
                                            realizarse todo el año, solo así
                                            podremos disfrutar de nuestros
                                            jardines durante todo el año.
                                        </p>

                                        <p className="marginTop">
                                            Talar un árbol requiere un análisis
                                            exhaustivo de distintos factores:
                                            entorno, tipo de árbol, tipo de
                                            maquinaria necesaria a emplear,
                                            operarios cualificados, gestión de
                                            residuos.
                                        </p>
                                        <p className="marginTop">
                                            En algunos casos puede ser necesario
                                            cortar el tráfico o solicitar
                                            autorización a los organismos
                                            públicos (ayuntamientos, etc.).
                                        </p>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Podas y talas{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            Talar un árbol requiere un análisis
                                            exhaustivo de distintos factores:
                                            entorno, tipo de árbol, tipo de
                                            maquinaria necesaria a emplear,
                                            operarios cualificados, gestión de
                                            residuos. En algunos casos puede ser
                                            necesario cortar el tráfico o
                                            solicitar autorización a los
                                            organismos públicos (ayuntamientos,
                                            etc.).
                                        </p>
                                        <p className="mb">
                                            Cada corte ha de aplicarse siguiendo
                                            el ángulo correcto en función al
                                            lugar donde debe caer la rama o
                                            tramo de árbol. En función de la
                                            especie arbórea se determinará la
                                            reducción de masa a podar, siempre
                                            respetando una determinada relación
                                            de masa podada respecto a masa total
                                            de árbol. La tala de árboles es
                                            selectiva, es decir, puede requerir
                                            la eliminación además de ciertas
                                            partes del árbol o ciertos árboles
                                            enfermos para que los adyacentes se
                                            desarrollen de forma sana y
                                            correcta. La poda ornamental resulta
                                            muy beneficiosa tanto para los
                                            arbustos como para las coníferas, ya
                                            que además de darles una forma
                                            atractiva, permite mantener su
                                            follaje renovado y optimizar la
                                            sanidad de la planta. Las mejores
                                            épocas para esta práctica suelen ser
                                            los meses de abril y agosto.
                                            Disponemos de todo tipo de productos
                                            fitosanitarios para aquellos casos
                                            en los que fuese necesario algún
                                            tratamiento posterior, bien sea
                                            herbicida, insecticida o fungicida.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img src={podas} alt="podas" />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Desbroces{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p className="mb">
                                            Realizamos desbroces despejando
                                            maleza, broza, malas hierbas,
                                            tocones, maderas, escombros o
                                            cualquier material seleccionado en
                                            el proyecto de desbroce. Contamos
                                            con todo tipo de maquinaria
                                            necesaria para garantizar el máximo
                                            rendimiento en cada caso, ya sean
                                            pequeñas parcelas o grandes áreas,
                                            montes, propiedades privadas,
                                            jardines, etc. Coordinamos todas las
                                            fases, desde la acción mecánica
                                            hasta la acción química (aplicación
                                            de tratamiento fitosanitarios) si
                                            fuese precisa. - Nos encargamos de
                                            la retirada de residuos vegetales y
                                            su posterior traslado a un punto
                                            según la normativa vigente. Nuestro
                                            personal altamente cualificado le
                                            ofrece la garantía de cuidar de su
                                            terreno sin afectar a la vegetación
                                            existente. Antes de realizar el
                                            trabajo de desbroce, no dude en
                                            ponerse en contacto con nosotros
                                            para poder asesorarle con la mayor
                                            profesionalidad.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img src={desbroces} alt="podas" />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Fumigaciones{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p className="mb">
                                            La prevención y detección temprana
                                            es vital para evitar la aparición de
                                            enfermedades u hongos que ataquen a
                                            nuestras plantas. Existen soluciones
                                            naturales respetuosas con el medio
                                            ambiente, sin embargo, es inevitable
                                            acudir a productos químicos
                                            especialmente diseñados para acabar
                                            con las enfermedades o plagas más
                                            comunes. Previamente se debe tener
                                            en cuenta la zona, si es agrícola o
                                            residencial, dado que cambiará el
                                            tipo de componente o sustancia a
                                            aplicar. La aplicación de productos
                                            fitosanitarios deben ser aplicados
                                            únicamente por personal cualificado.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <img src={fumigar} alt="podas" />
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Riegos{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p>
                                            Para un manejo eficiente del agua de
                                            riego se deben adoptar medidas que
                                            permitan establecer un consumo en
                                            función de las necesidades del
                                            jardín y que permitan asegurar el
                                            mantenimiento de las plantas en su
                                            estado óptimo. Entre estas medidas
                                            se destaca la elección de sistemas
                                            de riego con una mayor eficiencia de
                                            aplicación; la determinación de las
                                            necesidades hídricas de las plantas
                                            que componen el jardín; la
                                            realización de los riegos al
                                            atardecer o durante la noche, con la
                                            mayor periodicidad posible entre
                                            ellos; la práctica del riego
                                            deficitario que permita la
                                            supervivencia de la vegetación, y el
                                            diseño de jardines eficientes en el
                                            uso del agua.
                                        </p>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center marginTitleH2">
                                        Plantaciones{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <ul>
                                            <li>
                                                <b>Limpieza del terreno</b>:
                                                retirar escombros, basuras y
                                                restos inadecuados, eliminar
                                                hierbas y rastrojos...
                                            </li>
                                            <li>
                                                <b>Movimiento de tierras</b>
                                                Movimiento de tierras: aportar
                                                una capa adecuada de tierra
                                                vegetal, generalmente suele
                                                oscilar entre 10 y 20
                                                centímetros de espesor, siendo
                                                esta capa cuanto más espesa,
                                                mejor.
                                            </li>
                                            <li>
                                                <b>
                                                    Obras de albañilería y
                                                    equipamientos
                                                </b>
                                                : una vez se encuentre el
                                                terreno limpio y moldeado en sus
                                                relieves, se inician las obras
                                                de albañilería y la instalación
                                                de los equipamientos del jardín
                                                como la instalación de la
                                                iluminación, elementos de
                                                drenaje... En algunos casos este
                                                tipo de obras puede anteceder al
                                                aporte de tierra vegetal en el
                                                terreno.
                                            </li>
                                            <li>
                                                <b>Instalación riego</b>:
                                                instalación de aspersores para
                                                el césped, difusores para el
                                                césped y zonas estrechas (con
                                                alcance de hasta 5 metros),
                                                colocación de sistemas de riego
                                                por goteo en árboles, alcorques
                                                o zonas que estén fuera del
                                                césped, para arbustos, parterres
                                                de vivaces, huertos, frutales…
                                                estando todos estos elementos
                                                integrados en un sistema de
                                                riego automático.
                                            </li>
                                            <li>
                                                <b>Preparación del terreno</b>:
                                                para la plantación y a la
                                                siembra del césped se vuelven a
                                                limpiar los restos de materiales
                                                de obra, escombros, maleza,
                                                piedras grandes, etc., se labra
                                                el suelo a unos 25 cm de
                                                profundidad.
                                            </li>
                                            <li>
                                                <b>Plantaciones y siembras</b>:
                                                se trazan las dimensiones de los
                                                hoyos para árboles, arbustos y
                                                para los macizos marcando con
                                                cal o yeso, se mezcla la tierra
                                                extraída con abono orgánico, y
                                                se procede a trasplantar la
                                                planta de su contenedor de
                                                transporte a su alojamiento
                                                definitivo con cuidado de no
                                                estropear las raíces, se
                                                rellenan los hoyos con la tierra
                                                abonada hasta el nivel del
                                                cuello, dando un primer riego
                                                abundante.
                                            </li>
                                            <li>
                                                <b>Siembra de césped</b>: se
                                                puede plantar sembrando semillas
                                                o poniendo tepes (planchas). Si
                                                la superficie de césped es
                                                pequeña, el tema de los tepes
                                                puede ser interesante, dependerá
                                                principalmente de las prisas que
                                                se tengan. Ahora bien, un jardín
                                                mediano o grande, hay que
                                                estudiar detenidamente si merece
                                                la pena, puesto que los tepes
                                                salen mucho más caros. Se puede
                                                sembrar semilla, esperar un mes
                                                y medio y se tiene un buen
                                                césped por mucho menos dinero.
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <MyGallery /> */}
                    <CookieConsent buttonText="Ok!">
                        This website does not use cookies, only those generated
                        by Youtube.
                    </CookieConsent>
                    <TransparentFooter />
                </div>
            </>
        );
    }
}

import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import HerbicidasPageHeader from "../../../components/Headers/HerbicidasPageHeader";
import TransparentFooter from "../../../components/Footers/TransparentFooter";
import { NavbarCollapse } from "../../../lib/navbarCollapse";
import MyGallery from "../../../components/myComponents/gallery";

// core components
export default class TratamientosPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <NavbarCollapse />
                <div className="wrapper">
                    <HerbicidasPageHeader />
                    <div className="section section-about-us">
                        <Container>
                            <Row>
                                <Col
                                    className="ml-auto mr-auto text-center"
                                    md="8"
                                >
                                    <h5 className="description moreWeight">
                                        La calidad de la distribución de los
                                        productos fitosanitarios es fundamental
                                        para conseguir una buena eficacia
                                        biológica y para minimizar el riesgo
                                        sobre el medio ambiente durante la
                                        aplicación.
                                    </h5>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview ">
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center">
                                        Tratamientos fitosanitarios{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="6" className="mt">
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/herbicidas/fumigador.jpeg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                    <Col md="5">
                                        <p className="marginTop">
                                            Producto Fitosanitario o Plaguicida
                                            se define como aquella sustancia o
                                            mezcla de sustancias destinada a la
                                            prevención y cura de enfermedades de
                                            las plantas. - La calidad de la
                                            distribución de los productos
                                            fitosanitarios es fundamental para
                                            conseguir una buena eficacia
                                            biológica y para minimizar el riesgo
                                            sobre el medio ambiente durante la
                                            aplicación. La utilización de las
                                            técnicas y de las tecnologías
                                            adecuadas para la aplicación de
                                            productos fitosanitarios es un
                                            requisito de la máxima importancia
                                            para conseguir la eficacia deseada
                                            en el control de plagas,
                                            enfermedades y malas hierbas, a la
                                            vez que se reduce el riesgo de
                                            impacto ambiental de los
                                            tratamientos fitosanitarios. - Para
                                            la aplicación de cualquier producto
                                            fitosanitario es obligatorio
                                            disponer de un carnet de homologado
                                            de aplicador de tratamientos
                                            fitosanitarios.
                                        </p>
                                    </Col>
                                </Row>
                                <div className="separator separator-primary"></div>
                                <div className="section-story-overview ">
                                    <h2 className="ml-auto mr-auto text-center">
                                        Tratamientos herbicidas{" "}
                                    </h2>
                                </div>
                                <Row>
                                    <Col md="6">
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    require("../../../assets/img/herbicidas/herbicidas1.jpg") +
                                                    ")",
                                            }}
                                        ></div>
                                    </Col>
                                    <Col md="5">
                                        <p className="marginTop">
                                            Un herbicida es un producto químico
                                            o no que se utiliza para evitar o
                                            interrumpir el desarrollo de plantas
                                            indeseadas, también conocidas como
                                            “malas hierbas”, en un determinado
                                            terreno.
                                        </p>
                                        <p className="marginTop">
                                            La utilización de las técnicas y de
                                            las tecnologías adecuadas para la
                                            aplicación de productos herbicidas
                                            es un requisito de la máxima
                                            importancia para conseguir la
                                            eficacia deseada, a la vez que se
                                            reduce el riesgo de impacto
                                            ambiental de los tratamientos.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <MyGallery /> */}
                    <CookieConsent buttonText="Ok!">
                        This website does not use cookies, only those generated
                        by Youtube.
                    </CookieConsent>
                    <TransparentFooter />
                </div>
            </>
        );
    }
}
